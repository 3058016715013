.code-wrapper {
  display: flex;
  justify-content: center; }
  .code-wrapper .code {
    border: none;
    height: 72px;
    width: 52px;
    border-bottom: 3px solid #aeaeae;
    color: transparent;
    text-shadow: 0 0 0 #101010;
    text-align: center;
    font-size: 70px;
    outline: none; }
    .code-wrapper .code.focus {
      border-bottom: 3px solid #101010; }
    .code-wrapper .code:not(:last-child) {
      margin-right: 26px; }
    .code-wrapper .code::selection {
      background: none; }
